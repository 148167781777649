@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.animate-slide-in-left {
  animation: slideInLeft 0.5s ease-out;
}
/*animation for contentSideBar */
@keyframes slideInRightToLeft {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.animate-fadeOut {
  animation: fadeOut 5s ease-in-out forwards;
}

.animate-slide-in-right {
  animation: slideInRightToLeft 0.5s ease-out;
}
/*animation for closing ContentSideBar*/
@keyframes right {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}

.animate-close-content-side-bar {
  animation: right 1s ease-out;
}

@keyframes slideOutLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.animate-slide-out-left {
  animation: slideOutLeft 0.5s ease-in;
}
@keyframes controlAnimation {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.animate-control {
  position: fixed;
  color: white;
  border-radius: 5px;
  animation: controlAnimation 0.3s ease forwards;
  opacity: 0;
}
/*Animation for Sidebar width*/
@keyframes animateWidth {
  0% {
    width: 10%;
  }
  100% {
    width: 5%;
  }
}

.animate-left {
  animation: animateWidth 0.2s ease-in;
}
@keyframes animateWidth2 {
  0% {
    width: 5%;
  }
  100% {
    width: 10%;
  }
}

.animate-right {
  animation: animateWidth2 0.2s ease-out;
}

/*animation for sidebar text*/
@keyframes fadeOutText {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.animate-text {
  animation: fadeOutText 1s ease-in;
}

/*animation for CommentAdd*/
@keyframes slideInFromTop {
  from {
    transform: translateY(-5%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.form-animation {
  animation: slideInFromTop 0.5s ease-in-out forwards;
}

/* blur control when it is not applicable */
.bg-blur {
  filter: blur(4px);
}
